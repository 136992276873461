import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { NgPipesModule } from '../../shared/ng-pipes/ng-pipes.module';
import { UiModule } from '../../ui/ui.module';

import { LookupComponent } from './lookup.component';

@NgModule({
  declarations: [LookupComponent],
  imports: [
    CommonModule,
    UiModule,
    FormsModule,
    TranslateModule,
    NgPipesModule,
  ],
  exports: [LookupComponent],
})
export class LookupModule {}
